.sidebar_logo {
  max-width: 150px;
  margin: 20px auto 20px 35px;
  display: block;
}

.sidebarmenu_main > div {
  margin-top: 20px;
}

.sidebarmenu_main > div.logoutSection {
  margin-top: 0px;
}

.ant-layout-sider.ant-layout-sider-dark.sidebar_main {
  background: #fff;
  width: 260px !important;
  max-width: 260px !important;
  min-width: 260px !important;
  flex: 0 0 260px !important;
  min-height: 100vh;
  z-index: 2;
  border-right: 1px solid #dbdbdb;
}

.layoutBody {
  overflow: auto;
  min-height: calc(100vh - 78px);
  height: calc(100vh - 78px);
}


.fullLayoutBody {
  overflow: auto;
  min-height: 100vh;
  height: 100vh;
}

.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-below.ant-layout-sider-zero-width.sidebar_main {
  flex: 0 0 0px !important;
  max-width: 0px !important;
  min-width: 0px !important;
  width: 0px !important;
}

.ant-menu .ant-menu-item a::before {
  background: white !important;
}

.sidebar_main {
  height: 100vh;
  overflow: auto;
  background: #181A20 !important;
}

.activePage li {
  background: white;
  color: black;
}

.menuItemSidebar {
  background: transparent !important;
  color: white !important;
  display: flex;
  flex-direction: column;
}

.menuIcon {
  margin-right: 8px;
}


.sidebarmenu_main .sidebar_scroll .menuItemSidebar .client_refer  {
  width: 80%;
  margin: auto;
  margin-bottom: 10px;
  height: auto !important;
  padding: 3px 10px !important;
  border-radius: 12px;
}

.sidebarmenu_main .sidebar_scroll .menuItemSidebar .client_refer span {
  flex: unset !important;
}

.sidebarmenu_main .sidebar_scroll .menuItemSidebar .client_refer a{
  text-decoration:  none !important;
  transition: color 0s !important;
  font-size: medium;
}

.sidebarmenu_main .sidebar_scroll .menuItemSidebar .client_refer img{
  width: 20px;
  height: auto;  
  object-fit: scale-down;
}

.sidebarmenu_main .sidebar_scroll .menuItemSidebar .client_refer:hover {
  color: #000 !important;
  background-color: #fff !important;
}

.sidebarmenu_main .sidebar_scroll .menuItemSidebar .client_refer:hover img{
  filter: brightness(0);
}

.dropdown, .dropdown:hover, .customToggle.dropdown-toggle, .dropdown * {
  background-color: transparent !important;
  color: #000000;
}

.headerIcon {
  cursor: pointer;
}

.sidebar_main .ant-menu-item a svg, .ant-menu-item .ant-menu-title-content svg {
  margin-right: 10px;
  max-width: 20px;
  color: #606e79;
  height: 16px;
  width: 16px;
}

.margin-l-2 {
  margin-left: 2px;
}

.sidebarmenu_main {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 1200px) {
  .logoutSection {
    position: absolute;
    bottom: 15px;
    width: 100%;
  }
}

.sidebar_main .ant-menu-item {
  padding: 0px 35px !important;
  height: inherit !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.sidebar_main .ant-menu-item a {
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #a51111;
}

.sidebar_main .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 102, 0, 0.1);
}

.sidebar_main .ant-menu-item::after {
  border: 0px !important;
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
  display: none;
}

@media (max-width: 1199px) {
  .ant-layout-sider.ant-layout-sider-dark.sidebar_main {
    position: fixed;
  }
}

@media (max-height: 500px) {
  .sidebar_scroll {
    max-height: calc(100vh - 99px);
    overflow-y: scroll;
  }
}

.ant-layout-sider-zero-width-trigger {
  top: 13px;
}

.header_main {
  border-bottom: 1px solid #dbdbdb;
}

.header_main .navbar {
  background-color: #fff;
  margin-left: auto;
  padding: 13px 30px;
}

.header_main form {
  display: flex;
  align-items: center;
}

.header_main form input {
  padding: 9px 10px;
  min-width: 260px;
  border-radius: 4px;
  border: 0px;
  border: 1px solid #c3c3c3;
  margin-right: 10px;
  height: 45px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.header_main form input:focus-visible {
  outline: none;
}

.header_main form input::placeholder {
  font-weight: 600;
  letter-spacing: 0.5px;
}

.header_main .header_section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header_main form button {
  padding: 9px 20px;
  border-radius: 4px;
  border: 0px;
  background: var(--duotax-orange);
  color: #fff;
  font-size: 14px;
  height: 45px;
}

.call {
  background-color: #3182ed;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-right: 15px;
}

.call img {
  margin-right: 10px;
}

.call:hover {
  color: #fff;
}

.user_name {
  display: inline-block;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-right: 15px;
  color: var(--duotax-black);
}

.user_name:hover {
  background-color: #f4f4f4;
  color: var(--duotax-black);
}

.user_name img {
  width: 25px;
  margin-right: 6px;
  margin-top: -4px;
}

.userSetting {
  margin-left: 10px;
}

.setting {
  display: inline-block;
}

.setting img {
  width: 20px;
}

.right_menu {
  display: flex;
  align-items: center;
}

.resUser {
  margin-right: 20px;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.mdLogo {
  display: none;
}

@media (max-width: 1199px) {
  .header_main .navbar {
    justify-content: space-between;
  }

  .header_section .navbar-nav {
    display: none;
  }

  .header_main .header_section, .header_main form {
    display: block;
    width: 100%;
    margin-top: 20px;
  }

  .mdLogo {
    display: inline-block;
    float: left;
    flex: 1;
  }

  .mdLogo .sidebar_logo {
    margin: 0px auto;
    margin-left: 20px;
    height: 30px;
  }

  .header_main form input {
    width: calc(100% - 93px);
  }

  .ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left .anticon {
    vertical-align: 0 !important;
    line-height: 40px;
  }

}


@media (max-width: 699px) {

  .header_main .resUser {
    display: none;
  }

  .header_section .navbar-nav {
    display: inline-block;
    width: 100%;
  }

  .mdLogo .sidebar_logo {
    margin: 0 auto;
    margin-left: calc(50% - 50px);
  }

  .header_main form input {
    min-width: auto;
  }

  .header_section .navbar-nav a.user_name {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0px;
  }

  .user_name > img {
    margin-right: 0px;
  }

  .header_section .navbar-nav a {
    margin-right: 0px !important;
    margin-top: 10px !important;
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}


@media (max-width: 360px) {
  .mdLogo .sidebar_logo {
    max-width: 100px;
    margin-left: calc(50% - 25px);
  }
}
.drawerActionIcon{
  font-size: 20px;
}