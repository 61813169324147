.tableContainer {
  width: 100%;
  overflow: auto;
}

.tableContainer, .tableButtons .row table {
  font-size: 16px;
  font-weight: 500;
}

.tableButtons {
  font-size: 16px;
  font-weight: 500;
}

.uploadImage, .footerImage {
  width: 53px;
  height: 80px;
  object-fit: cover;
}

.count {
  background: #1C5E3C;
  color: white;
}

.chevronsDown {
  border: 1px solid #D2D2D2;
  border-radius: 4px;
}

.userDetail{
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 16px;
}

.toggleAction input:checked{
  background-color: black;
  border: none;
}

.toggleAction input:focus{
  box-shadow: none;
}

.paginationAction button, .paginationAction button:hover, .paginationAction button:focus-visible, .paginationAction button:first-child:active, .paginationAction button:not(button-check)+ button:active {
  background: white !important;
  color: black !important;
  border: 1px solid #C9C9C9 !important;
  border-radius: 50px;
  box-shadow: none;
}

.paginationActiveButton {
  border: 1px solid black;
}



@media only screen and (max-width: 988px) {
  .searchInput {
    margin-top: 12px;
  }
}

.imageTable tr th:nth-child(2) span{
  width: 100px;
  display: inline-block;
}
.imageTable tr th:nth-child(1){
  width: 100px;
}
.imageTable tr th:nth-child(2){
/* .imageTable tr th:nth-child(3){ */
  width: 120px;
} 
.imageTable tr th:nth-child(7),.imageTable tr th:last-child{
  width: 290px;
}

.imageTable tr td:nth-child(4),.imageTable tr td:nth-child(5), .imageTable tr th:nth-child(4), .imageTable tr th:nth-child(5), .imageTable tr td:nth-child(6),.imageTable tr th:nth-child(6){
  text-align: center;
}