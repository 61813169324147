.tableContainer {
  width: 100%;
  /* overflow: auto; */
}

.tableContainer, .tableButtons .row table {
  font-size: 16px;
  font-weight: 500;
}

/* .uplodImageCard {
  border: 1px solid #CCCCCC;
  border-radius: 4px;
} */

.imagepart {
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  width: 100%;
}

.label {
  font-size: 14px;
  font-weight: 500;
  opacity: 50%;
}

.tableContainer input[type="file"] label {
  display: none;
}

/* .tableContainer input {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 8px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #CCCCCC;
  background: url(../../assets/imageUploadPlus.png) center center no-repeat #e4e4e4;
  border-radius: 8px;
  cursor: pointer;
} */

.indexRow{
  width: 200px;
}

.otherImg {
  width: calc(100% - 380px);
  overflow-y: auto;
}

.tableImages{
  height: 100px;
  object-fit: cover;
}

.actionButtonCancel {
  background: white !important;
  border: 1px solid #1C5E3C !important;
  color: #1C5E3C !important;
  border-radius: 50px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.actionButtonSave {
  background: #1C5E3C !important;
  color: white !important;
  border-radius: 50px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  border: none !important;
}

.actionsButton {
  font-size: 18px;
  font-weight: 500;
}

.exampleimage {
  height: 100px;
  width: 66px;
}

.tableButtons {
  font-size: 16px;
  font-weight: 500;
  width: 281px;
  height: 50px;
}

.uploadImage {
  width: 53px;
  height: 80px;
}

.profileImage {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.icon {
  cursor: pointer;
}

.imagesLists{
  width: calc(100% - 260px);
  overflow: auto;
}

.toggleAction input:checked{
  background-color: black;
  border: none;
}

.toggleAction input:focus{
  box-shadow: none;
}

.paginationAction button, .paginationAction button:hover, .paginationAction button:focus-visible, .paginationAction button:first-child:active, .paginationAction button:not(button-check)+ button:active {
  background: white !important;
  color: black !important;
  border: 1px solid #C9C9C9 !important;
  border-radius: 50px;
  box-shadow: none;
}

.paginationActiveButton {
  border: 1px solid black;
}