.tableContainer {
  width: 100%;
  overflow: auto;
}

.tableContainer, .tableButtons .row table {
  font-size: 16px;
  font-weight: 500;
}

.tableButtons {
  font-size: 16px;
  font-weight: 500;
  width: 281px;
  height: 50px;
}

.allUserFilterButtons {
  padding: 12px 20px;
  border: 1px solid #C9C9C9;
  background-color: white;
  border-radius: 50px 0 0 50px;
}

.activeButton {
  padding: 12px 20px;
  border: 1px solid #C9C9C9;
  background-color: #C9C9C9;
}

.freeFilterButtons {
  padding: 12px 20px;
  border: 1px solid #C9C9C9;
  background-color: white;
}

.paidFilterButtons {
  padding: 12px 20px;
  border: 1px solid #C9C9C9;
  background-color: white;
}

.paidButton {
  background: #ADE4CA;
  color: #007E37;
  font-weight: 700;
}

.searchInput {
  border-radius: 50px;
  border: 2px solid #C9C9C9;
}

.searchInput span, .searchInput input, .searchInput input:focus {
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  background-color : unset;
  border: unset;
  box-shadow: unset;
  border-radius: unset;
}

.freeButton {
  color: #5D5D5D;
  font-weight: 700;
}

.paginationOption label {
  display: none;
}

.paginationOption select {
  border-radius: 50px;
  border: 2px solid #C9C9C9;
  width: 70px;
}

.toggleAction input:checked{
  background-color: black;
  border: none;
}

.toggleAction input:focus{
  box-shadow: none;
}

.paginationAction button, .paginationAction button:hover, .paginationAction button:focus-visible, .paginationAction button:first-child:active, .paginationAction button:not(button-check)+ button:active {
  background: white !important;
  color: black !important;
  border: 1px solid #C9C9C9 !important;
  border-radius: 50px;
  box-shadow: none;
}

.paginationActiveButton {
  border: 1px solid black;
}

@media only screen and (max-width: 768px) {
  .searchInput, .paginationOption {
    margin-top: 12px;
  }

  .paginationOptionContainer {
    justify-content: unset!important;
  }
}