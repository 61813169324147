.title {
  color: #667085;;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.count {
  font-size: 24px;
  font-weight: 500;
  color: #333843;
}