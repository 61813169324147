.ant-menu-item-selected{
  color: #000 !important;
  background-color: #fff !important;
}
.ant-menu-item-selected img{
  filter: brightness(0);
}
.search-input button{
  display: none !important;
}
.search-input div.input-group{
  flex: 1;
}
.request_table td{
  /* text-align: center; */
  vertical-align: middle;
}
.rdt_Pagination div:last-child button, .paginationAction button, .paginationAction button:hover, .paginationAction button:focus-visible, .paginationAction button:first-child:active, .paginationAction button:not(button-check)+ button:active {
  background: white !important;
  color: black !important;
  border: 1px solid #C9C9C9 !important;
  border-radius: 50px !important;
  box-shadow: none;
  padding: 0px !important;
}

.paginationAction button, .rdt_Pagination div:last-child button{
  margin: 3px !important;
}
.editView{
  position: relative;
}
.editView img{
  width: 60px !important;
}
.examplePage .editView img{
    width: 80px !important;
    height: 120px !important;
    object-fit: cover;
}
.actionBtn{
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.actionBtn button, .actionBtn label{
  background: rgba(0,0,0,0.5);
  border: 0px !important;
  border-radius: 6px;
  margin: 2px;
  color: #fff;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottomImageView{
  width: 100%;
  overflow-y: auto;
}
.uplodImageCard{
    display: inline-block;
    width: 120px;
    height: 120px;
    margin: 8px;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA/SURBVHgB7ZLBCQAgDAODuP+KdZOKWJ8xYMFXD/IKHIUGuGORZzxCaUhSgi1Yb3KSA+stfYGidvBD0EU/RI8JQhUUafJhXLEAAAAASUVORK5CYII=) center center no-repeat #e4e4e4;
    border-radius: 8px;
    cursor: pointer;
}
.exImgList{
  align-items: center;
}
.exImgList .exImg img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.unionIcon{
  width: 26px !important;
  height: auto !important;
  border-radius: 0px !important;
  margin-right: 10px;
  object-fit: none !important;
}
.indexRow{
  width: 80px;
}
.exImg{
  width: 150px;
}
.actionMenu{
  width: 150px;
}
.otherImg{
  width: calc(100% - 380px);
  overflow-y: auto;
}
.transparentBtn{
  background: transparent;
  border: 0px;
  margin: 5px;
}
.App .settingTransBtn{
  background-color: transparent !important;
}
.preDates{
  list-style-type: none;
  padding: 0;
}
.preDates li {
  float: left;
    padding: 5px 10px;
    border: 1px solid #C9C9C9;
    border-radius: 4px;
    margin: 5px;
}
.calenderBtn{
  border: 2px solid #c9c9c9;
  height: 45px;
  width: 45px;
  border-radius: 50px;
}
.selectedBox{
  background-color: #C9C9C9;
}
.filterTypes ul{
  list-style-type: none;
}
.filterTypes ul li svg{
  height: 20px !important;
  width: 20px !important;
}
.radioButtons li label input{
  height: 16px !important;
  width: 16px !important;
}
.radioButtons li label{
  display: flex;
  align-items: center;
}
.filterTypes ul li{
  display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
}
.characters{
  list-style-type: none;
  padding: 0px;
}
.characters li, .border-bottom-1px {
  border-bottom : 1px solid #D2D2D2;
}
.display-grid{
  display: grid;
}
.mr-05{
  margin-right: 5px;
}

.user_tables_style div[role="cell"], .user_tables_style .rdt_TableCol{
  flex-basis: unset !important;
  flex-grow: unset !important;
}

.user_tables_style div[data-column-id="1"],.user_tables_style div[data-column-id="5"]{
  width: 100px;
}

.user_tables_style div[data-column-id="2"]{
  width: 120px;
}
.user_tables_style div[data-column-id="8"]{
  width: 400px;
}
.user_tables_style div[data-column-id="3"],.user_tables_style div[data-column-id="4"],.user_tables_style div[data-column-id="7"]{
  width: 200px;
}
.user_tables_style div[data-column-id="9"]{
  width: 350px;
}
/* .user_tables_style div[data-column-id="8"]{
  width: 150px;
} */
/* .user_tables_style div[data-column-id="9"]{
  width: 120px;
} */
.user_tables_style div[data-column-id="7"], .user_tables_style div[data-column-id="8"],.user_tables_style div[data-column-id="9"]{
  justify-content:center;
}

.example-dash-tables tr td p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.userCounter{
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.greenColor{
  color: #01B763 !important;
}

.userCounter li{
  margin: 0px 5px;
}
.userCounter li svg{
  margin-right:5px;
}

.viewallBtn {
  background: #1C5E3C !important;
  color: white !important;
  border-radius: 50px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  border: none !important;
  padding: 8px 14px;
  text-decoration: none;
}
.feedbackTable tr td:last-child{
  width: 25%;
}
.feedbackTable tr td:nth-child(5){
  width: 20%;
}
.asset_section img, .asset_section video{
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.replace_label{
  background: #000;
  color: #fff;
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  border-radius: 6px;
  padding: 4px;
  text-align: center;
  cursor: pointer;
}
.image-grid-view{
  flex-flow: wrap;
}
.image-grid-view img{
  width: 70px;
  height: 110px;
  object-fit: cover;
}
.close-btn{
  background: white !important;
  border: 1px solid #1C5E3C !important;
  color: #1C5E3C !important;
  border-radius: 50px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.user_view_link, .user_view_link:hover{
  text-decoration: none;
  color: #000;
}
.fade.modal.show{
  background: rgba(0,0,0,0.5);
}
.ant-layout-sider-collapsed .logoImg{
  width: 40px;
}
.flex-1{
  flex: 1;
}

/* .rdt_Pagination div:nth-child(2), .rdt_Pagination span:nth-child(1){
  display:  none !important;
} */
.rdt_Pagination{
  justify-content: flex-start !important;
}

.rdt_Pagination div:nth-child(2){
  border-radius: 50px;
  border: 2px solid #C9C9C9;
  width: 70px;
  padding: 8px;
}

.rdt_Pagination div:nth-child(2) svg{
  height: 40px;
}

.rdt_Pagination span:nth-child(1), .rdt_Pagination span:nth-child(3){
  font-size: 16px;
  font-weight: bold;
}

.rdt_Pagination span:nth-child(3), .rdt_Pagination span:nth-child(4){
  display:flex;
  margin-left: auto;
}

.advertisementSec label{
  opacity: 1 !important;
}

.socialImages div[data-column-id="2"]{
  max-width: 100px;
}
.socialImages div[data-column-id="4"]{
  min-width: 250px !important;
}

*{
  outline: 0 !important;
}

.rdt_Pagination {
  justify-content: space-between;
}

.dropdown-menu.show{
  background-color: #fff !important;
}
.reject_checkbox{
  height: 20px;
  width: 20px;
}


.imageTable div[data-column-id="8"],.socialImgTable.imageTable div[data-column-id="9"]{
  min-width: 170px;
}
.imageTable div[data-column-id="7"],
.completeImg div[data-column-id="7"],.socialImgTable.imageTable div[data-column-id="8"]{
  min-width: 245px;
}
.imageTable div[data-column-id="9"],
.completeImg div[data-column-id="8"],.socialImgTable.imageTable div[data-column-id="10"]{
  min-width: 290px;
}
.imageTable div[data-column-id="3"],
.completeImg div[data-column-id="3"], .socialImgTable.imageTable div[data-column-id="7"]{
  max-width: 60px;
  min-width: 60px;
}


.notificationIconBtn{
  background:transparent !important;
  border: 0px;
  border-radius: 50px;
  border:1px solid;
  margin-left: 10px;
  padding: 2px 6px 6px 6px !important;
}
.userDetailsTable{
  min-width: 1100px;
  overflow-x: auto;
}
.notificationIconBtn:active, .notificationIconBtn:active *{
    background-color: #66BD50 !important;
    color: #fff !important;
}
.simplePagination.simplePagination button, .simplePagination.simplePagination button:hover, .simplePagination.simplePagination button:active{
  margin: 2px !important;
  padding: 5px 10px !important;
}
.request-table-scroll{
  height: calc(100vh - 240px);
  overflow-y: scroll;
}
div[data-tag="allowRowEvents"]{
  white-space: normal !important;
}
.imageTable > div{
  display: inline-block;
  width: auto !important;
  min-width: 100% !important;
}
.feedbackTable div[data-column-id="5"] > div, .feedbackTable div[data-column-id="6"] > div{
  white-space: normal !important;
  text-overflow: unset;
  padding: 10px 5px;
}

div[data-tag="allowRowEvents"]{
  overflow: visible !important;
}
.bellIcon{
    position: relative;
    height: 35px;
    width: 35px;
    border-radius: 50px;
    border: 1px solid #000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.table-loader{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  opacity: 1;
  z-index: 99;
}

.table-loader .ring
{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:180px;
  height:180px;
  background:transparent;
  border:3px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  line-height:180px;
  font-family:sans-serif;
  font-size:16px;
  color:#ffffff;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px #ffffff;
  box-shadow:0 0 20px rgba(0,0,0,.5);
}
input.h-50{
  height: 50px !important;
}
.table-loader .ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #ffffff;
  border-right:3px solid #ffffff;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
.table-loader span
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.table-loader span:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#ffffff;
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px #ffffff;
}


.confirm-box__overlay{
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.confirm-box .confirm-box__content{
  width: 30vw;
  max-width: 30vw;
  text-align: center;
  padding: 3rem;
}
.confirm-box .confirm-box__content span{
  font-size: 1.4vw;
  font-weight: 600;
  margin-bottom: 15px;
  display: inline-block;
}
.confirm-box{
  left: calc(50% - 15vw) !important;
  top: 40% !important;
}
.confirm-box .confirm-box__content button{
  width: 7.5vw;
  padding: 0.4vw;
  border-radius: 0.3vw;
  font-size: 1.1vw;
  color: #fff;
  border: 0px;
  font-weight: 600;
}
.confirm-box .confirm-box__content .confirm-box__actions{
  justify-content: center;
}

button[role="confirmable-button"]{
  background: #fe5e5b;
}

button[role="cancellable-button"]{
  background:#5500E0 !important;
}

@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}


.bellIcon svg{
  height: 20px;
  width: auto;
}
.bellIcon span{
  position: absolute;
  bottom: -5px;
  left: 60%;
  background: #000;
  color: #fff;
  padding: 1px 5px;
  border-radius: 15px;
  font-size: 13px;
}
.loader_screen{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  text-align: center;
  background: rgba(0,0,0,0.6);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  position: relative;
  width: 75px;
  height: 100px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#DDD 50px, transparent 0),
                    linear-gradient(#DDD 50px, transparent 0),
                    linear-gradient(#DDD 50px, transparent 0),
                    linear-gradient(#DDD 50px, transparent 0),
                    linear-gradient(#DDD 50px, transparent 0);
  background-size: 8px 100%;
  background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  animation: pillerPushUp 4s linear infinite;
}
.loader:after {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #de3500;
  border-radius: 50%;
  animation: ballStepUp 4s linear infinite;
}

@keyframes pillerPushUp {
  0% , 40% , 100%{background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px}
  50% ,  90% {background-position: 0px 50px, 15px 58px, 30px 66px, 45px 78px, 60px 90px}
}

@keyframes ballStepUp {
  0% {transform: translate(0, 0)}
  5% {transform: translate(8px, -14px)}
  10% {transform: translate(15px, -10px)}
  17% {transform: translate(23px, -24px)}
  20% {transform: translate(30px, -20px)}
  27% {transform: translate(38px, -34px)}
  30% {transform: translate(45px, -30px)}
  37% {transform: translate(53px, -44px)}
  40% {transform: translate(60px, -40px)}
  50% {transform: translate(60px, 0)}
  57% {transform: translate(53px, -14px)}
  60% {transform: translate(45px, -10px)}
  67% {transform: translate(37px, -24px)}
  70% {transform: translate(30px, -20px)}
  77% {transform: translate(22px, -34px)}
  80% {transform: translate(15px, -30px)}
  87% {transform: translate(7px, -44px)}
  90% {transform: translate(0, -40px)}
  100% {transform: translate(0, 0);}
}
  