.tableContainer {
  width: 100%;
  overflow: auto;
}

.tableContainer, .tableButtons .row table {
  font-size: 16px;
  font-weight: 500;
}

.tableButtons {
  font-size: 16px;
  font-weight: 500;
}

.tableButtons button{
  height: 40px;
  line-height: 16px;
}

.activeButton {
  padding: 12px 20px;
  border: 1px solid #C9C9C9;
  background-color: #C9C9C9;
}

.allUserFilterButtons {
  padding: 12px 20px;
  border: 1px solid #C9C9C9;
  background-color: white;
}

.freeFilterButtons {
  padding: 12px 20px;
  border: 1px solid #C9C9C9;
  background-color: white;
}

.paidFilterButtons {
  padding: 12px 20px;
  border: 1px solid #C9C9C9;
  background-color: white;
}

.paidButton {
  background: #ADE4CA;
  color: #007E37;
  font-weight: 700;
}

.searchInput {
  border-radius: 50px;
  border: 2px solid #C9C9C9;
}

.searchInput span, .searchInput input, .searchInput input:focus {
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  background-color : unset;
  border: unset;
  box-shadow: unset;
  border-radius: unset;
}

.freeButton {
  color: #5D5D5D;
  font-weight: 700;
}

.paginationOption label {
  display: none;
}

.paginationOption select {
  border-radius: 50px;
  border: 2px solid #C9C9C9;
  width: 70px;
}

.downloadButton {
  color: white;
  background: #66BD50;
  width: 147px;
  border-radius: 4px;
}

.uploadButton {
  color: white;
  background: #DE4841;
  border-radius: 8px 0 0 8px;
}

.issubmitDisable {
  color: white;
  background: #C3C9D0;
}

.downArrow {
  background: #66BD50;
  border-radius: 0 8px 8px 0;
  padding: 12px;
}

.submitButton {
  color: white;
  background: #FF3737;
}

.uploadImage, .footerImage {
  width: 53px;
  height: 80px;
  object-fit: cover;
}

.count {
  background: #1C5E3C;
  color: white;
  line-height: 24px !important;
}

.chevronsDown {
  border: 1px solid #D2D2D2;
  border-radius: 4px;
}

.toggleAction input:checked{
  background-color: black;
  border: none;
}

.toggleAction input:focus{
  box-shadow: none;
}

/* .paginationAction button, .paginationAction button:hover, .paginationAction button:focus-visible, .paginationAction button:first-child:active, .paginationAction button:not(button-check)+ button:active {
  background: white !important;
  color: black !important;
  border: 1px solid #C9C9C9 !important;
  border-radius: 50px !important;
  box-shadow: none;
}

.paginationAction button{
  margin: 3px !important;
} */

.approveButton {
  cursor: pointer;
  background: #66BD50;
  padding: 6px 12px;
  color: white;
  border-radius: 50px;
}

.rejectedButton {
  background: #DD4242;
  cursor: pointer;
  padding: 6px 12px;
  color: white;
  border-radius: 50px;
}

.paginationActiveButton {
  border: 1px solid black;
}

@media only screen and (max-width: 988px) {
  .searchInput {
    margin-top: 12px;
  }
}