.formContainer button {
  background: #1C5E3C !important;
  font-weight: 500;
  font-size: 18px;
  text-transform: unset !important;
}

.formTitle {
  font-weight: 500;
  font-size: 18px;
}

.imageContainer {
  border-radius: 8px;
  border: 1px solid #CCCCCC;
}

.label {
  font-weight: 500;
  opacity: 50%;
}

.formContainer button{
  border: none;
}

.settingFrame {
  width: 150px;
  border-radius: 4px;
}

.settingFrameImage {
  width: 365px;
  height: 200px;
  border-radius: 4px;
}

.savePackButton{
  width: 200px;
}

@media only screen and (max-width: 1437px) {
  .settingFrameImage {
    width: 300px;
  }
}

@media only screen and (max-width: 410px) {
  .formContainer {
    width: 100%;
    overflow: auto;
  }
}