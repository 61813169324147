.loginContainer {
    width: 400px;
    background: #ffffff;
    font-size: 16px;
    border-radius: 24px !important;
    padding: 50px 40px;
    border: none !important;
    box-shadow: -3px -3px 7px #ffffff73, 2px 2px 16px #00000033;
  }
  
  .text {
    font-weight: 600;
    margin-bottom: 35px;
    color: #000;
    font-size: 34px;
  }
  
  .loginContainer .text{
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 35px;
    color: #000;
  }
  
  .loginContainer .field{
    font-size: 18px;
    height: 50px;
    width: 100%;
    display: flex;
    position: relative;
  }
  
  .field input{
    height: 100%;
    width: 100%;
    padding-left: 25px;
    font-size: 18px;
    outline: none;
    border: none;
    color: #595959;
    background: #f5f5f5;
    border-radius: 25px;
    box-shadow: inset 2px 2px 5px #babecc,
    inset -5px -5px 10px #ffffff73;
  }
  
  .field input:focus ~ label{
    box-shadow: inset 2px 2px 5px #babecc,
    inset -1px -1px 2px #ffffff73;
  }
  
  .field:nth-child(2){
    margin-top: 20px;
  }
  
  .field span{
    position: absolute;
    width: 50px;
    line-height: 50px;
    color: #595959;
  }
  
  .field label{
    position: absolute;
    top: 50%;
    left: 25px;
    pointer-events: none;
    color: #666666;
    transform: translateY(-50%);
  }
  
  .field input:focus ~ label{
    opacity: 0;
  }
  
  .forgotPass {
    text-align: left;
    color: #3498db;
    font-size: 18px;
    margin: 10px 0 10px 5px;
    cursor: pointer;
  }
  
  .forgotPass:hover{
    text-decoration: underline;
  }
  
  .signInButton{
    margin: 20px 0 !important;
    width: 100%;
    height: 50px;
    color: #000;
    font-size: 20px !important;
    font-weight: 600;
    background: #f5f5f5;
    border: none;
    outline: none;
    cursor: pointer !important;
    border-radius: 25px !important;
    box-shadow: 2px 2px 5px #babecc,
    -5px -5px 10px #ffffff73;
  }
  
  .signInButton:focus{
    color: #3498db;
    box-shadow: inset 2px 2px 5px #babecc,
    inset -5px -5px 10px #ffffff73;
  }